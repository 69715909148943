<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link to="/order">订购流程</router-link>
      </div>
    </div>
    <div class="order-wrap">
      <img
        class="order-process-pc"
        src="@/assets/order_process.png"
      >
      <img
        class="order-process-m"
        src="@/assets/order_process_m.png"
      >
      <div class="gxdz">
        <img
          class="title"
          src="@/assets/gxdz_title.png"
        >
        <div class="con">
          <p>您需要将定制的想法或者图纸先行与业务人员沟通，方案确定后，交付定金，我们会按照您的要求制作您定制的作品。</p>
          <ul>
            <li>
              <span>1</span>
              <p>一般个性定制，是在剑身上雕刻名字，这个比较简单，是不收费的。</p>
            </li>
            <li>
              <span>2</span>
              <p>您相中了某一款，但是不喜欢剑身或者剑鞘的材质，可以根据您的要求定制调换。</p>
            </li>
            <li>
              <span>3</span>
              <p>更改剑身的长短，比如健身剑或者其他剑您觉得长度不合您的要求，都可定制。</p>
            </li>
            <li>
              <span>4</span>
              <p>新品的定制，您有自己的想法与设计，想定制一款您自己的剑，包括装具的设计，剑鞘的设计，剑条、剑盒等都可定制，您可以提供图纸，也可以提供想法，我们有专业的设计人员为您设计。</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="shfw">
        <img
          class="title"
          src="@/assets/shfw_title.png"
        >
        <ul class="con">
          <li>
            <span class="title">退换货承诺</span>
            <div class="con">
              <p>由于产品质量或者运输导致的产品损坏，在签收商品起3日之内，我厂可为您办理退换货。具体操作方法参照退换货流程。 由于用户使用不当导致的产品损坏，我厂可为您提供维修服务，运输费用及维修所需部件成本费用由客户承担。</p>
            </div>

          </li>
          <li>
            <span class="title">退换货流程</span>
            <div class="con">
              <p>1. 收到货品时，可打开商品包装检查商品是否完好无损，如果商品存在质量问题请整单拒收，不支持部分拒收。</p>
              <p>2. 为保障您的权益，请与配送员当面核对商品种类、数量、规格、赠品、金额等是否与订单一致，准确无误再进行签收。配送员离开后，我厂将不再为以上问题负责。</p>
              <p>3. 如需退换货，请联系客服咨询办理，自签收商品之日起3天内我厂可以为您办理退换货。</p>
              <p>4. 退换商品需包装完好，请将相关赠品，于退换货时一并退回，以确保正常为您办理退换货。</p>
              <p>5. 退换货产生的费用由客户垫付，我厂收到货检验后符合退货政策的，会将费用退还给用户。</p>
              <p>6. 退款方式与您的支付方式一致，同时收回促销礼券、赠品及相应积分，银行卡退款到账时间与各银行业务相关。</p>
            </div>
          </li>
          <li>
            <span class="title">如何退款</span>
            <div class="con">
              <p>
                <b>线上支付：</b>
                <span>退款方式与您的支付方式一致，如使用支付宝等账号的余额支付功能，退款将返回到账户余额。(银行卡退款到账时间与各银行业务相关，我们并不能保证到账时间) </span>
              </p>
              <p>
                <b>线下支付：</b>
                <span>如果您采用的是线下支付方式，退货时我们的客服将与您商定退款方式，达成一致后将按照约定的方式退款。</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Order',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .bread{
    // width: 1760px;
    margin: 0 auto;
  }
  .wrap {
    background: #f2f2f2;
    padding: 0 20px;
    > .order-wrap {
      background: #fff;
      > .order-process-pc {
        width: 100%;
      }
      > .order-process-m {
        display: none;
      }
      > .gxdz {
        background: url('~@/assets/gxdz_bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 0 117px 96px;
        overflow: hidden;
        > .title {
          display: block;
          margin: -49px auto 39px;
        }
        > .con {
          background: rgba(51, 51, 51, 0.59);
          padding: 77px 103px 168px 125px;
          > p {
            font-size: 24px;
            color: #ffffff;
            line-height: 33px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
          > ul {
            margin-top: 36px;
            > li {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 18px;
              > span {
                display: block;
                width: 32px;
                line-height: 32px;
                background: #a20722;
                font-size: 22px;
                font-weight: bold;
                color: #ffffff;
                text-align: center;
                height: 32px;
                margin-right: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
              }
              > p {
                flex: 1;
                font-size: 18px;
                color: #ffffff;
                line-height: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
            }
            > li:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
      > .shfw {
        padding-bottom: 84px;
        > .title {
          display: block;
          margin: 0 auto;
        }
        > .con {
          > li {
            margin-bottom: 44px;
            > .title {
              display: inline-block;
              padding: 0 78px;
              line-height: 78px;
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              background: linear-gradient(270deg, #ffffff 0%, #f8efe3 100%);
              margin-bottom: 34px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            > .con {
              padding: 0 118px;
              > p {
                font-size: 18px;
                color: #333333;
                line-height: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                > b {
                  font-weight: bold;
                  font-size: 20px;
                  font-family: PingFangSC-Medium, PingFang SC;
                }
                > span {
                  display: block;
                  text-indent: 80px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .order-wrap {
      background: #fff;
      > .order-process-m {
        width: 100%;
      }
      > .order-process-pc {
        display: none;
      }
      > .gxdz {
        background: url('~@/assets/gxdz_bg_m.png') no-repeat;
        background-size: 100% auto;
        background-color: #000;
        margin: 0 12px;
        overflow: hidden;
        > .title {
          display: none;
        }
        > .con {
          margin-top: 18%;
          background: rgba(51, 51, 51, 0.59);
          padding: 16px 15px 17px;
          > p {
            font-size: 12px;
            color: #ffffff;
            line-height: 21px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
          > ul {
            margin-top: 13px;
            > li {
              display: flex;
              justify-content: flex-start;
              // margin-bottom: 5px;
              > span {
                display: block;
                width: 11px;
                line-height: 13px;
                background: #a20722;
                font-size: 9px;
                font-weight: bold;
                color: #ffffff;
                text-align: center;
                height: 13px;
                margin-right: 5px;
                margin-top: 5px;
                font-family: PingFangSC-Semibold, PingFang SC;
              }
              > p {
                flex: 1;
                font-size: 12px;
                color: #ffffff;
                line-height: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
            }
            > li:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
      > .shfw {
        > .title {
          display: block;
          margin: 0 auto;
          width: 100%;
        }
        > .con {
          margin-top: 20px;
          > li {
            margin-bottom: 20px;
            > .title {
              display: inline-block;
              padding: 0 16px;
              line-height: 46px;
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              background: linear-gradient(270deg, #ffffff 0%, #f8efe3 100%);
              margin-bottom: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            > .con {
              padding: 0 15px;
              > p {
                font-size: 14px;
                color: #333333;
                line-height: 26px;
                font-family: PingFangSC-Regular, PingFang SC;
                > b {
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: bold;
                  font-size: 14px;
                }
                > span {
                  display: block;
                  text-indent: 56px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>